<svelte:options tag="fds-datetime-picker"></svelte:options>
<script>
    import {onMount, createEventDispatcher, beforeUpdate} from 'svelte';
    import flatpickr from 'flatpickr';
    import {German} from "flatpickr/dist/l10n/de.js";
    import {stylestr} from './styles';
    import 'flatpickr/dist/flatpickr.css'
    import 'flatpickr/dist/themes/light.css'
    import fdsHelper from "@fds-components/fds-helper"
    import {name, version as componentversion} from '../package.json';
    let path = fdsHelper.get_href()
    let newValueInternal;
    let stylestring = stylestr;
    let styleadded = false;
    let isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );

    import {get_current_component} from "svelte/internal";

    const component = get_current_component();
    const dispatch = createEventDispatcher();
    let newoptions

    const hooks = new Set([
        'onChange',
        'onOpen',
        'onClose',
        'onMonthChange',
        'onYearChange',
        'onReady',
        'onValueUpdate',
        'onDayCreate',
    ]);

    export let onChange, onOpen, onClose, onMonthChange, onYearChange, onReady, onValueUpdate, onDayCreate = null;
    /**
     * Current date value in GMT
     * @type {string}
     */
    export let value = '';
    /**
     * date format for input value
     */
    export let valueformat;
    /**
     * Current date value in timestamp
     * @type {string}
     */
    export let timeStampValue = '';
    /**
     * Language of all fps, available options [en,de]
     * @type {'en'|'de'}
     */
    export let defaultlang = 'de';
    /**
     *  Language of fp, available options [en,de]
     *  @type {'en'|'de'}
     */
    export let langid = null;
    /**
     *  contains the selected date(s)'s formatted string.
     * @type {string}
     */
    export let formattedValue = '';


    /**
     * Type of picker available options datetime,date,time
     * @type {'datetime'|'date'|'time'}
     */
    export let type = null;
    /**
     * If set to true then range picker is activated
     * @type {boolean}
     */
    export let range = null;
    /**
     * Configuration options see below
     * @type {{}}
     */
    export let options = {};
    /**
     * optional selector on which datepisker would be created
     * @type {string}
     */
    export let input = undefined;
    /**
     * flatpickr instance
     * @type {HTMLElement}
     */
    export let fp = null;
    export {fp as flatpickr};

    let element = null;
    let ready = false;
    let datevalue = null;
    let container = null;
    let styleel = null;

    $: if (fp && ready) {
        setTimeout(() => {
            if(isMobile) input.shadowRoot.querySelector('input').type="hidden"
        }, 500);
    }

    $: if (fp && ready && value) {
        fp.setDate(value, false, valueformat);
        if (value.length){
            updateValue(fp.selectedDates, value)
        }
    }


    $:setProps()

    /**
     * list of all theme props
     * @type {{}}
     */
     let prop = {};

    /**
     * Set theme
     * @type  {"" | "classic" | "rounded" | "underline" | "whiteShadow"}
     */
    export let theme=""
    /**
     * Sets the status which defines a different border color
     * @type  {"default" | "error" | "warning" | "success"}
     */
    export let status="default"
    /**
     * Sets the size
     * @type  {"default" | "lg" | "sm" }
     */
    export let size="default"

    /**
     * The text field width in CSS value (e.g. 200px)
     * @type {string}
     */
    export let width=""

    /**
     * Set input to grey disabled state and deactivates editing
     * @type {boolean }
     */
    export let disabled=false
    /**
     * Shows an icon at front of input
     * @type {iconString}
     */
    export let icon_prepend=""
    /**
     * Shows an icon at end of input
     * @type {iconString}
     * */
    export let icon_append=""










    /**
     * Get information about component
     * @param  {("api" | "examples" | "css")} type the info type
     */
    export async function getInfo(type) {
        if (type==="version"){
            return new Promise(resolve => {
                resolve(componentversion);
            });
        }
        let res = await fdsHelper.getInfo(type,name);
        return res;
    }

    /**
     * version of component
     * @type {string}
     */
    export const version = componentversion;



    function setProps() {
        if (window["fds-ui-config"] && !window["fds-ui-config"].getTheme) {
            if (input) {
                window["fds-ui-config"].getTheme = input.getTheme;
            } else {
                window["fds-ui-config"].getTheme = document.createElement("input-text").getTheme;
            }
        }
        if (window["fds-ui-config"] && window["fds-ui-config"].getTheme) {
            prop = window["fds-ui-config"].getTheme(status, size, disabled, theme);
        }

        if (width) prop.width = width

        width = prop.width
        if (!width) width = "100%"


        if (icon_prepend) {
            prop.paddingLeft = prop.iconHeight + 15 + "px";
        }
        prop.paddingRight = "5px";
        if (icon_append) {
            prop.paddingRight = prop.iconHeight + 15 + "px";
        }
    }


    onMount(() => {
        setProps()
        let elem = element || input;
        if (elem.shadowRoot) elem = elem.shadowRoot.querySelector("input");
        const opts = addHooks(options);
        opts.onReady.push(() => {
            ready = true;
        });

        newoptions = Object.assign(opts, element ? {wrap: true} : {});

        if (defaultlang === 'de') {
            flatpickr.localize(German);
        }


        if (valueformat) {
            newoptions = Object.assign(newoptions, {valueformat});
        }
        newoptions = Object.assign(newoptions, {appendTo: container});

        if (langid) {
            newoptions = Object.assign(newoptions, {locale: langid});
        }

        if ((type !== 'time' && !newoptions.locale && defaultlang === 'de') || (newoptions.locale && newoptions.locale === 'de')) {
            newoptions = Object.assign(newoptions, {dateFormat: 'd.m.Y'});
        }
        if (type && type === 'datetime') {
            if ((!newoptions.locale && defaultlang === 'de') || (newoptions.locale && newoptions.locale === 'de')) {
                newoptions = Object.assign(newoptions, {dateFormat: 'd.m.Y H:i'});
            }
            newoptions = Object.assign(newoptions, {enableTime: true});
        }
        if (type && type === 'date') {
            newoptions = Object.assign(newoptions, {enableTime: false});
        }
        if (type && type === 'time') {
            newoptions = Object.assign(newoptions, {enableTime: true, noCalendar: true});
        }
        if (range && (range === 'true' || range === true)) {
            newoptions = Object.assign(newoptions, {mode: "range"});
        }

        newoptions.allowInput=true

        fp = flatpickr(
            elem, newoptions
        );


        return () => {
          //  fp.destroy();
        };
    });
    beforeUpdate(() => {
        if (!styleadded && fp && stylestring) {
            styleadded = true;
            if (styleel && stylestring) {
                styleel.innerHTML = stylestring;
            }
        }
        setProps();
    })

    $: if (fp && ready) {
        for (const [key, val] of Object.entries(addHooks(options))) {
            fp.set(key, val);
        }
    }

    $:  if (timeStampValue && (!datevalue || new Date(timeStampValue * 1000).toUTCString() !== datevalue.toUTCString())) {
        value = new Date(timeStampValue * 1000).toUTCString();

    }

    function executeFunctionByName(functionName, selectedDates, dateStr, instance) {
        if (functionName === 'onChange' && onChange) {
            onChange(selectedDates, dateStr, instance);
        }
        if (functionName === 'onOpen' && onOpen) {
            onOpen(selectedDates, dateStr, instance);
        }
        if (functionName === 'onClose' && onClose) {
            onClose(selectedDates, dateStr, instance);
        }
        if (functionName === 'onMonthChange' && onMonthChange) {
            onMonthChange(selectedDates, dateStr, instance);
        }
        if (functionName === 'onYearChange' && onYearChange) {
            onYearChange(selectedDates, dateStr, instance);
        }
        if (functionName === 'onReady' && onReady) {
            onReady(selectedDates, dateStr, instance);
        }
        if (functionName === 'onValueUpdate' && onValueUpdate) {
            onValueUpdate(selectedDates, dateStr, instance);
        }
        if (functionName === 'onDayCreate' && onDayCreate) {
            onDayCreate(selectedDates, dateStr, instance);
        }
    }
    /**
     * @event onChange
     */

    /**
     * @event onOpen
     */

    /**
     * @event onClose
     */



    /**
     * @event onMonthChange
     */

    /**
     * @event onYearChange
     */

    /**
     * @event onReady
     */

    /**
     * @event onValueUpdate
     */

    /**
     * @event onDayCreate
     */
    function addHooks(opts = {}) {
        opts = Object.assign({}, opts);

        for (const hook of hooks) {
            const firer = (selectedDates, dateStr, instance) => {

                if (selectedDates && selectedDates.length) {
                    selectedDates = selectedDates.map((el) => {
                        let dateobj = new Date(el);
                        return {'iso': dateobj.toISOString(), 'timestamp': dateobj.getTime() / 1000, 'orginal': el}
                    })
                }

                dispatch(stripOn(hook), [selectedDates, dateStr, instance]);


                component.dispatchEvent(new CustomEvent(stripOn(hook), {
                    //detail: JSON.stringify( [selectedDates, dateStr]),
                    detail: [selectedDates, dateStr],
                    composed: true  // propagate across the shadow DOM
                }));
                executeFunctionByName(hook, selectedDates, dateStr, instance);

            };

            if (hook in opts) {
                // Hooks must be arrays
                if (!Array.isArray(opts[hook])) opts[hook] = [opts[hook]];

                opts[hook].push(firer);
            } else {
                opts[hook] = [firer];
            }
            opts.onValueUpdate = [ function(selectedDates, dateStr, instance) {
                updateValue(selectedDates, dateStr, instance);
            }];
        }

        if (opts.onChange && !opts.onChange.includes(updateValue)){
            opts.onChange.push(updateValue);
        }
        return opts;
    }

    /**
     * Force Trigger change on datetimepicker
     */
    export function triggerChange() {
        newoptions['onChange'].forEach(element => element(newValueInternal,formattedValue,flatpickr));
    }

    function updateValue(newValue, dateStr) {
        console.log("update value",newValue, dateStr);
        newValueInternal = newValue;
        value =
            Array.isArray(newValue) && newValue.length === 1
                ? newValue[0]
                : newValue;

        if (Array.isArray(value)) {
            value = value.map(el => el.toISOString())
        } else {
            if(value) value = value.toISOString();
        }

        formattedValue = dateStr;
        timeStampValue = Date.parse(value);
        datevalue = new Date(timeStampValue * 1000);



    }

    function stripOn(hook) {
        return hook.charAt(2).toLowerCase() + hook.substring(3);
    }
    function stripBack(hook) {
        return 'on'+hook.charAt(0).toUpperCase() + hook.substring(1);
    }


</script>


{#if element == null}
    <fds-input-text {theme} {status} {size} {icon_prepend} {icon_append} {disabled} width="{width}" {...$$restProps}
                    bind:this={input}></fds-input-text>
{/if}

<div style="position: relative" bind:this={container}/>

{#if stylestring}
    <style bind:this={styleel}/>
{/if}
